var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Project Name")]),_c('v-select',{attrs:{"placeholder":"None","label":"projectname","options":_vm.projectOption},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[(_vm.User == 'admin')?_c('b-form-group',[_c('label',[_vm._v("Type")]),_c('v-select',{attrs:{"placeholder":"None","label":"name","options":_vm.branchOptions},on:{"input":function($event){return _vm.getEmployee($event)}},model:{value:(_vm.branchname),callback:function ($$v) {_vm.branchname=$$v},expression:"branchname"}})],1):_vm._e()],1),(_vm.branchname && _vm.branchname.name !== 'All')?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Select "+_vm._s(_vm.branchname.name == "Admin" ? "Employee" : _vm.branchname.name))]),_c('v-select',{attrs:{"label":"fullname","placeholder":"None","options":_vm.$store.getters['master/getUsers'](_vm.branchname.id)},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var profile_image = ref.profile_image;
var username = ref.username;
var surname = ref.surname;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(name)+" "+_vm._s(surname))]),_c('span',[_vm._v("("+_vm._s(username)+")")])]}}],null,false,2200771238),model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('v-select',{attrs:{"placeholder":"None","label":"name","options":[
                            'Cancelled',
                            'Payment Pending',
                            'Payment Done',
                            'Vacant',
                            'Booked',
                            'Sold',
                            'Hold',
                            'Registered' ]},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Select Date")]),_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"value":"","placeholder":"Select Date","config":{
                            dateFormat: 'd/m/Y',
                            mode: 'range',
                          }},on:{"input":function($event){return _vm.getdata($event)}}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","disabled":_vm.flag},on:{"click":_vm.searchData}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SearchIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Search")])],1)],1)],1)])],1),(_vm.$store.state.app.customerDetailsModal)?_c('customer-modal'):_vm._e(),_c('report-table',{attrs:{"data":_vm.data,"columns":_vm.fields},scopedSlots:_vm._u([{key:"customField",fn:function(ref){
                          var props = ref.props;
return [(props.column.field == 'customer')?_c('span',[_c('div',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.$store.dispatch('app/getCustomerDetails', props.row.customer)}}},[_vm._v(" "+_vm._s(props.row.customer ? props.row.customer.fullname : "")+" ")])]):_vm._e()]}},{key:"printHeader",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%"}},[_c('h3',{staticStyle:{"margin":"0px"}},[_vm._v("Sales Report")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%","flex-direction":"column","align-items":"center"}},[(_vm.employee)?_c('h4',{staticStyle:{"margin":"0px"}},[_vm._v(" Employee Name : "+_vm._s(_vm.employee.fullname)+" ")]):_vm._e(),(_vm.result && _vm.result.length == 2)?_c('h5',{staticStyle:{"margin":"0px"}},[_vm._v(" "+_vm._s(_vm.result[0])+" to "+_vm._s(_vm.result[1])+" ")]):_vm._e()])]},proxy:true}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }