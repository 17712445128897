<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Project Name</label>
                          <v-select
                            v-model="project"
                            placeholder="None"
                            label="projectname"
                            :options="projectOption"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group v-if="User == 'admin'">
                          <label>Type</label>
                          <v-select
                            @input="getEmployee($event)"
                            v-model="branchname"
                            placeholder="None"
                            label="name"
                            :options="branchOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="branchname && branchname.name !== 'All'">
                        <b-form-group>
                          <label
                            >Select
                            {{
                              branchname.name == "Admin" ? "Employee" : branchname.name
                            }}</label
                          >
                          <v-select
                            v-model="employee"
                            label="fullname"
                            placeholder="None"
                            :options="$store.getters['master/getUsers'](branchname.id)"
                          >
                            <template
                              #option="{ name, profile_image, username, surname }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder">
                                {{ name }} {{ surname }}</span
                              ><span>({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Status</label>
                          <v-select
                            v-model="status"
                            placeholder="None"
                            label="name"
                            :options="[
                              'Cancelled',
                              'Payment Pending',
                              'Payment Done',
                              'Vacant',
                              'Booked',
                              'Sold',
                              'Hold',
                              'Registered',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            value=""
                            class="form-control"
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            @input="getdata($event)"
                            style="background-color: transparent"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                        :disabled="flag"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <customer-modal
                v-if="$store.state.app.customerDetailsModal"
              ></customer-modal>
              <report-table :data="data" :columns="fields">
                <template #customField="{ props }">
                  <span v-if="props.column.field == 'customer'">
                    <div
                      @click="
                        $store.dispatch('app/getCustomerDetails', props.row.customer)
                      "
                      class="text-primary cursor-pointer"
                    >
                      {{ props.row.customer ? props.row.customer.fullname : "" }}
                    </div>
                  </span>
                </template>
                <template #printHeader>
                  <div style="display: flex; justify-content: center; width: 100%">
                    <h3 style="margin: 0px">Sales Report</h3>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      width: 100%;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <h4 style="margin: 0px" v-if="employee">
                      Employee Name : {{ employee.fullname }}
                    </h4>
                    <h5 style="margin: 0px" v-if="result && result.length == 2">
                      {{ result[0] }} to {{ result[1] }}
                    </h5>
                  </div>
                </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
    BAvatar,
  },
  data() {
    return {
      flag: false,
      User: "",
      employee: "",
      project: "",
      branchname: "",
      data: "",
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      data1: [],
      users: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "customer",
          label: "Customer Name",
          dynamic: true,
          responseField: "fullname",
        },
        {
          field: "mobileno",
          label: "Mobile No",
        },
        {
          field: "email",
          label: "Email",
        },
        {
          field: "team",
          label: "CP/Franchise/Employee",
        },
        {
          field: "bookingdate",
          label: "Booking Date",
        },
        {
          field: "project",
          label: "Project",
        },
        {
          field: "plot",
          label: "Suggested Plot No",
        },
        {
          field: "plotrate",
          label: "Plot Rate",
        },
        {
          field: "plotareasqfeet",
          label: "Plot Area(Sq.Feet)",
        },
        {
          field: "discount",
          label: "Discount (%)",
        },
        {
          field: "plotvalue",
          label: "Plot Value",
        },
        {
          field: "paymenttermday",
          label: "Payment Terms Days",
        },
        {
          field: "paymenttype",
          label: "Payment Type",
        },
        {
          field: "paymentthrough",
          label: "Payment Through",
        },
        {
          field: "chequeno",
          label: "Cheque NO",
        },
        {
          field: "chequedate",
          label: "Cheque date",
        },
        {
          field: "bankname",
          label: "Bank Name",
        },
        {
          field: "refno",
          label: "Reference Number",
        },
        {
          field: "bookingdate",
          label: "Payment Date",
        },
        {
          field: "remarks",
          label: "Remarks",
        },
        {
          field: "installmentsdate",
          label: "Installment Start Date",
        },
        {
          field: "noofinstallment",
          label: "No. Of Installment",
        },
        {
          field: "monthgap",
          label: "Gap Between Months",
        },
        {
          field: "installmentamt",
          label: "Installment Amount",
        },
        {
          field: "firstpayment",
          label: "First Payment",
        },
        {
          field: "remainingpayment",
          label: "Remaining Payment",
        },
        {
          field: "totalpayment",
          label: "Total Payment",
        },
      ],
      projectOption: [],
      plots: [],
      employeeOptions: [],
      status: "",
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Employee", id: "employee" },
        { name: "All", id: "all" },
      ],
    };
  },
  watch: {
    "$store.state.master.projects"(newValue, oldValue) {
      this.projectOption = [{ projectname: "All", id: "All" }, ...newValue];
    },
    "$store.state.app.user_data"(newValue, oldValue) {
      this.User = this.$store.state.app.user_data.role;
    },
  },
  mounted() {
    this.User = this.$store.state.app.user_data.role;
    if (this.User !== "admin") {
      this.getEmployee({ id: this.User });
    }
    this.$store.dispatch("master/getProject");
  },
  methods: {
    async getEmployee(e) {
      this.employee = [];
      if (e && e.id == "channelpartner") {
        this.$store.dispatch("master/getChannelpartner");
      } else if (e && e.id == "franchise") {
        this.$store.dispatch("master/getFranchise");
      } else if (e && e.id == "employee") {
        this.$store.dispatch("master/getEmployee");
      }
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData(tableData) {
      const obj = {
        startdate: this.startdate,
        enddate: this.enddate,
        team: this.employee ? this.employee.id : "",
        project: this.project ? this.project.id : "",
        status: this.status,
      };
      this.flag = true;
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(obj),
        url: `${this.baseApi}/salesreport`,
      })
        .then((response) => {
          this.flag = false;

          this.data = response.data.data;

          this.data.map((item, index) => {
            item.chequedate =
              item.chequedate == false || item.chequedate == "00/00/0000"
                ? "-"
                : item.chequedate;
            item.installmentsdate =
              item.installmentsdate == false || item.installmentsdate == "00/00/0000"
                ? "-"
                : item.installmentsdate;
            item.team = item.team ? item.team.fullname : "";
            item.project = item.project ? item.project.projectname : "";
            item.plot = item.plotno ? item.plotno.plotno : "";
          });
        })
        .catch((error) => {
          this.flag = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
